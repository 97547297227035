<template>
  <div class="flex-box vertical">
    <div class="car-content">
      <a-card :bordered="false" title="车辆数据">
        <div class="piece-area-ctx flex-box align-center justify-between">
          <div class="piece-view">
            <div class="piece-view-value">{{ info.residue || 0 }} / {{ info.total || 0 }}</div>
            <div class="piece-view-title">剩余车位</div>
          </div>
          <div class="piece-view">
            <div class="piece-view-value">{{ info.admission || 0 }}</div>
            <div class="piece-view-title">入场车辆</div>
          </div>
          <div class="piece-view">
            <div class="piece-view-value">{{ info.exit || 0 }}</div>
            <div class="piece-view-title">出场车辆</div>
          </div>
        </div>
      </a-card>
    </div>
    <div class="flex-grow flex-box vertical content">
      <div class="data-title">
        <div class="rec-title-text">共{{ pagination.total }}条记录</div>
        <a-form-model class="query-form" layout="inline">
          <a-form-model-item>
            <a-date-picker
              v-model="form.date"
              :format="format"
              :value-format="format"
              placeholder="请选择日期"
              @change="updateList"
            />
          </a-form-model-item>
        </a-form-model>
        <a-space class="title-btn">
          <a-button @click="getList">查询</a-button>
          <a-button type="primary" :loading="exporting" @click="exportData">{{
            exporting ? "正在导出" : "数据导出"
          }}</a-button>
        </a-space>
      </div>
      <div class="data-area">
        <a-table
          ref="dataTable"
          :columns="columns"
          row-key="code"
          :data-source="list"
          :loading="loading"
          :pagination="pagination"
          :scroll="{ y: h }"
          @change="handleTableChange"
        >
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { downLoadBlobFile, getKeyTitle, getQuery } from "../../common/js/tool";
import { inOutOptions } from "../../common/constant/safe";

export default {
  name: "CarList",
  data() {
    return {
      format: "YYYY-MM-DD",
      h: 500,
      list: [],
      form: {},
      loading: false,
      columns: [
        { title: "时间", dataIndex: "carAccessTime" },
        { title: "车牌号码", dataIndex: "carNumber" },
        {
          title: "岗位",
          dataIndex: "position",
          customRender: (text) => <span>第{text}道岗</span>,
        },
        {
          title: "进/出",
          dataIndex: "carAccessType",
          customRender: (text) => <span>{getKeyTitle(inOutOptions, text, "value", "label")}</span>,
        },
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
      },
      searchKeyType: {
        date: 2,
      },
      info: {
        residue: 0,
        total: 0,
        admission: 0,
        exit: 0,
      },
      exporting: false,
    };
  },
  created() {
    this.getData();
    this.getList();
  },
  mounted() {
    this.setHeight();
    window.addEventListener("resize", this.setHeight, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setHeight, false);
  },
  methods: {
    getData() {
      this.$axios("/admin/car/top-data").then(res => {
        if (res) {
          this.info.residue = res.in;
          this.info.exit = res.out;
          this.info.residue = res.residue;
          this.info.total = res.total;
        }
      })
    },
    handleTableChange(pagination) {
      let pager = { ...this.pagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.pagination = pager;
      this.getList();
    },
    updateList() {
      this.pagination.current = 1;
      this.getList();
    },
    dealList(list) {
      return list;
    },
    getList() {
      this.loading = true;
      let url = `/admin/car/get-data`;
      let { current, pageSize } = this.pagination;
      url += `?page=${current}&pageSize=${pageSize}`;
      url += getQuery.call(this, this.searchKeyType);
      this.$axios({ url, noTempleFilter: true })
        .then((res) => {
          let list = this.dealList(res.data);
          this.list = list;
          if (res.page) {
            this.$set(this.pagination, "total", res.page.totalCount);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setHeight() {
      let dataTale = this.$refs.dataTable;
      if (dataTale) {
        let area = this.$el.querySelector(".data-area");
        this.h = area.clientHeight - 54 - 64;
      }
    },
    exportData() {
      if (this.exporting) return;
      this.exporting = true;
      let url = `/admin/car/export`;
      this.$axios(url, { noTempleFilter: true, responseType: "blob" })
        .then((res) => {
          downLoadBlobFile(res, "车辆数据");
        })
        .finally(() => {
          this.exporting = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../common/less/safe.less");

.car-content {
  width: calc(100% - 32px);
  margin: 16px 16px 0;
  flex-shrink: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
}
</style>
